body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; }

header.header {
  height: 3.5em;
  margin-bottom: 0.5em; }

.navbar-brand > a {
  background-image: url(/assets/images/logo.png);
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  padding-inline-start: 1.5em; }

footer {
  width: auto;
  padding: 1rem;
  margin: 0.5rem 1rem;
  text-align: center;
  border-top: 1px dotted gray; }

.glyphicon-with-text > .glyphicon {
  margin-right: 0.5em; }

@media (min-width: 768px) {
  footer {
    max-width: 85%;
    margin-left: auto;
    margin-right: auto; } }
